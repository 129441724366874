export function parseImageName(src: string) {
  const regExp =
    /(?:wixstatic.com\/media\/)(.*.(gif|jpe?g|tiff?|png|webp|bmp))(?:\/)/i;
  const wixStatic = regExp.exec(src);
  if (wixStatic && wixStatic[1]) {
    return wixStatic[1];
  }
  const filename = src.substring(src.lastIndexOf('/') + 1);
  if (/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(filename)) {
    return filename;
  }
  return src;
}

export function parseWidthHeight(src: string) {
  const re = /(?:[wh]_)(\d+)/g;
  try {
    const width = Number(re.exec(src)?.[1]);
    const height = Number(re.exec(src)?.[1]);
    return { width, height };
  } catch (e) {
    return {};
  }
}
