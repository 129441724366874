import { DraftBlockType, RawDraftContentBlock } from '../types';

const seenKeys = {};
const MULTIPLIER = Math.pow(2, 24);

export class RawDraftContentBlockBuilder {
  private readonly block: RawDraftContentBlock = {
    data: {},
    depth: 0,
    entityRanges: [],
    inlineStyleRanges: [],
    key: generateRandomKey(),
    text: '',
    type: DraftBlockType.UNSTYLED,
  };

  withText(text: string) {
    this.block.text = text;
    return this;
  }

  withAtomic() {
    this.withText(' ');
    this.block.type = DraftBlockType.ATOMIC;
    const entity = {
      offset: 0,
      length: this.block.entityRanges.length + 1,
      key: `${this.block.entityRanges.length}`,
    };
    this.block.entityRanges.push(entity);
    return this;
  }

  build() {
    return this.block;
  }
}

function generateRandomKey() {
  let key;

  while (key === undefined || seenKeys.hasOwnProperty(key) || !isNaN(+key)) {
    key = Math.floor(Math.random() * MULTIPLIER).toString(32);
  }

  (seenKeys as any)[key] = true;
  return key;
}
