import { RawDraftContentState } from '../types';
import { RawDraftContentBlockBuilder } from './RawDraftContentBlockBuilder';
import { PluginTypes } from '../plugins/pluginTypes';
import { ImageContent } from '../plugins/types/ImageContent';
import { ImageConfig, ImageContentBuilder } from './ImageContentBuilder';

export class RawDraftContentBuilder {
  private readonly raw: RawDraftContentState<any> = {
    blocks: [],
    entityMap: {},
  };
  withText(text: string) {
    const textBlock = new RawDraftContentBlockBuilder().withText(text).build();
    this.raw.blocks.push(textBlock);
    return this;
  }

  withEntity(type: PluginTypes, config?: any, src?: any) {
    const n = this.raw.blocks.length;
    const block = new RawDraftContentBlockBuilder().withAtomic().build();
    this.raw.blocks.push(block);
    this.raw.entityMap[n] = {
      type,
      mutability: 'IMMUTABLE',
      data: {
        config,
        src,
      },
    };
    return this;
  }

  withImage({ config, src }: ImageContent) {
    return this.withEntity(PluginTypes.Image, config, src);
  }

  withImageFromUrl(url: string) {
    const builder = new ImageContentBuilder();
    builder.withSrcFromUrl(url).withConfig(ImageConfig.FULL_WIDTH);
    return this.withImage(builder.build());
  }

  withVersion(ver: string) {
    this.raw.VERSION = ver;
    return this;
  }
  build() {
    return this.raw;
  }
}
