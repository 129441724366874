import { getFullImageDescriptor } from 'common/ContentEditor/getFullImageDescriptor';
import { Image, ImageContent } from '../plugins/types/ImageContent';
import { MediaConfig } from '../plugins/types/Media';

export enum ImageConfig {
  FULL_WIDTH = 'FULL_WIDTH',
}

type ConfigByType = {
  [key in keyof typeof ImageConfig]: MediaConfig;
};
const imageConfigByType: ConfigByType = {
  [ImageConfig.FULL_WIDTH]: {
    alignment: 'center',
    size: 'fullWidth',
    showTitle: true,
    showDescription: true,
  },
};

export class ImageContentBuilder {
  private readonly content: ImageContent = {
    config: undefined as any,
    src: undefined as any,
  };

  withSrcFromUrl(url: string) {
    const imageDescriptor = getFullImageDescriptor({
      targetHeight: 0,
      targetWidth: 0,
      url,
    });

    return this.withSrc({
      id: imageDescriptor?.relativeUrl,
      file_name: imageDescriptor?.relativeUrl,
      original_file_name: imageDescriptor?.relativeUrl,
      height: imageDescriptor?.sourceHeight,
      width: imageDescriptor?.sourceWidth,
    } as any);
  }

  withSrc(src: Image) {
    this.content.src = {
      file_name: '',
      height: 0,
      id: '',
      original_file_name: '',
      width: 0,
      ...(src as any),
    };
    return this;
  }

  withConfig(type: ImageConfig) {
    this.content.config = imageConfigByType[type] || {
      alignment: '',
      size: '',
    };
    return this;
  }

  build() {
    return this.content;
  }
}
